import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import S3 from "react-aws-s3";
import { Login, History, GroupLink, CredentialsForm } from "./components";
import Util from "./util";
import { Buffer } from "buffer";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
window.Buffer = Buffer;

const App = () => {
  const [s3Img, setS3Img] = useState("");
  const [history, setHistory] = useState([]);
  const [copy, setcopy] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [grouplinks, setgrouplink] = useState("");
  const [logged, setLogged] = useState(false);
  const ref = useRef();
  const ref2 = useRef();
  const refWindow = useRef(window);
  const [drag, setdrag] = useState(false);
  const [listener, setListener] = useState(false);
  
  var ReactS3Client = new S3({
    bucketName: "shot.screen1.me",
    region: "us-east-1",
    s3Url: "https://s3.amazonaws.com/shot.screen1.me",
  });
  const [awsValidate, aws] = React.useState(false);

  const notify = () =>
    toast.error("403 (Forbidden) - bad credentials", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  useEffect(() => {
    const util = new Util();
    const cookie = util.getCookie("_bwtoken");
    if (cookie) {
      if (cookie == util.matchCode(null, true)) setLogged(true);
    }
    let aws_cookie = util.getCookie('_awscookie')
    if(aws_cookie){
      aws_cookie = JSON.parse(window.atob(aws_cookie));
      const valueParsed = JSON.stringify({ key: aws_cookie.key, secret: aws_cookie.secret })
      sessionStorage.setItem(
      "accessAWS",
      valueParsed
    );
    refWindow.current.window.addEventListener("paste", paste);
    setListener(true);
    aws(true);
    
    }
    return () => {
      window.removeEventListener("paste", paste, true);
    };
  }, []);

  window.onbeforeunload = () => {
    return "wait!";
  };
  const paste = useCallback((data) => {
    var items = (data.clipboardData || data.originalEvent.clipboardData).items;

    for (let index in items) {
      var item = items[index];
      if (item.kind === "file") {
        var blob = item.getAsFile();

        var reader = new FileReader();

        reader.onload = function (data) {
          console.log("image", data.target.result);
        }; // data url!

        reader.readAsDataURL(blob);
          let keys = JSON.parse(sessionStorage.getItem("accessAWS"));
          if(!keys)
            {
              const util = new Util();
              let aws_cookie = util.getCookie('_awscookie')
            if(aws_cookie){
              keys = JSON.parse(window.atob(aws_cookie));
            }
          }

          ReactS3Client.config.accessKeyId = keys.key;
          ReactS3Client.config.secretAccessKey = keys.secret;
          ReactS3Client.uploadFile(blob)
            .then((data) => {
              console.log(data);
              setS3Img(data);
              setHistory((old) => [...old, data.key]);
              let local = localStorage.getItem('files');
              let localJSON = []
              if(local){
              localJSON = JSON.parse(local);
              }
              localJSON = [...localJSON,data.key];
  
              localStorage.setItem('files',JSON.stringify(localJSON));
              
            })
            .catch((e) => {
              notify();
              console.error(e);
            });
       
      }
    }
  })
  const clipboard = () => {
    ref.current.select();
    document.execCommand("copy");
    ref.current.setSelectionRange(0, 99999);
    ref.current.focus();

    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 3000);
  };
  const remove = useCallback(() => {
    ReactS3Client.deleteFile("shot.screen1.me/" + s3Img.key)
      .then((data) => {
        setHistory(history.filter((h) => h !== s3Img.key));
        setS3Img(data);
        setcopy(false);
      })
      .catch((e) => console.error(e));
  });
  const removeSpecific = useCallback((key) => {
    ReactS3Client.deleteFile("shot.screen1.me/" + key)
      .then((data) => {
        setHistory(history.filter((h) => h !== key));
        setS3Img(data);
        setcopy(false);
      })
      .catch((e) => console.error(e));
  });

  // const mouserenter = () => {
  //   alert("asdasd")
  // }
  const upload = useCallback((e) => {
    var input = e.target;
    var url = input.value;
    var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
    if (
      input.files &&
      input.files[0] &&
      (ext == "gif" ||
        ext == "png" ||
        ext == "jpeg" ||
        ext == "jpg" ||
        ext == "mp4")
    ) {
      var reader = new FileReader();
      reader.onload = function (e) {
        ref2.current.setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      const file = input.files[0];
      ReactS3Client.uploadFile(file)
        .then((data) => {
          console.log(data);
          setS3Img(data);
          setHistory((old) => [...old, data.key]);
          setdrag(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  });
  const changeAWS = (i = false) => {
    aws(false);
  };
  const handleAWS = (i) => {
    const valueParsed = JSON.stringify({ key: i.accessKeyId, secret: i.secretAccessKey })
    sessionStorage.setItem(
      "accessAWS",
      valueParsed
    );
    
    let cookieObj = new Util();

    cookieObj.setCookie('_awscookie',window.btoa(valueParsed),7);

    if (!listener) refWindow.current.window.addEventListener("paste", paste);
    setListener(true);

    aws(i);
  };
  const dragO = () => {
    setdrag(true);
  };
  const dragL = () => {
    setdrag(false);
  };

  return (
    <Wrapper>
      {!logged ? (
        <Login valid={(val) => setLogged(val)}></Login>
      ) : (
        <>
          {awsValidate && <div className="submitted-key">Keys submitted (cookies)</div>}

          <CredentialsForm
            aws={(i) => handleAWS(i)}
            change={(i) => changeAWS(i)}
          ></CredentialsForm>

          <History
            list={history}
            grouplinks={grouplinks}
            toggle={toggle}
            toglebehavior={(st) => setToggle(st)}
            setgrouplink={(item, remove) =>
              setgrouplink((old) =>
                remove
                  ? old.replace("http://shot.screen1.me/" + item, "")
                  : old + item
              )
            }
            removeSpecific={(key) => removeSpecific(key)}
          />
          <div className="name-url">
            {s3Img.location ? (
              <>
                <span
                  className="clipboard-copy"
                  title="copy to clipboard"
                  onClick={() => clipboard()}
                >
                  <Icon />{" "}
                </span>
                <input
                  ref={ref}
                  value={"http://shot.screen1.me/" + s3Img.key || ""}
                  onChange={(e) => e.target.value}
                ></input>
              </>
            ) : (
              "CTRL + V (clipboard)"
            )}{" "}
            {copy && <div className="copied">Copied!</div>}
          </div>
          <br />

          <div className="s3-upload">
            <div
              className="uploadZone"
              onDragOver={() => dragO()}
              onDragLeave={() => dragL()}
              style={{ backgroundColor: drag ? "#e91e63" : "#272727" }}
            >
              <input
                type="file"
                id="upload-input"
                onChange={(e) => upload(e)}
              ></input>
              { <img ref={ref2}></img> }
            </div>
            {s3Img.location && (
              <div className="viewer">
                <div className="remove" onClick={() => remove()}>
                  x
                </div>
                <Image
                  src={
                    s3Img.key.indexOf(".mp4") > 0
                      ? "https://cdn-icons-png.flaticon.com/512/92/92353.png"
                      : s3Img.location
                  }
                ></Image>
              </div>
            )}
          </div>
          <GroupLink
            copylist={grouplinks}
            setgrouplink={(i) => setgrouplink(i)}
          ></GroupLink>
        </>
      )}
      <ToastContainer />
    </Wrapper>
  );
};
const Icon = () => (
  <svg
    height="32px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 512 512"
    width="32px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path d="M160,160h192c-1.7-20-9.7-35.2-27.9-40.1c-0.4-0.1-0.9-0.3-1.3-0.4c-12-3.4-20.8-7.5-20.8-20.7V78.2    c0-25.5-20.5-46.3-46-46.3c-25.5,0-46,20.7-46,46.3v20.6c0,13.1-8.8,17.2-20.8,20.6c-0.4,0.1-0.9,0.4-1.4,0.5    C169.6,124.8,161.9,140,160,160z M256,64.4c7.6,0,13.8,6.2,13.8,13.8c0,7.7-6.2,13.8-13.8,13.8c-7.6,0-13.8-6.2-13.8-13.8    C242.2,70.6,248.4,64.4,256,64.4z" />
        <path d="M404.6,63H331v14.5c0,10.6,8.7,18.5,19,18.5h37.2c6.7,0,12.1,5.7,12.4,12.5l0.1,327.2c-0.3,6.4-5.3,11.6-11.5,12.1    l-264.4,0.1c-6.2-0.5-11.1-5.7-11.5-12.1l-0.1-327.3c0.3-6.8,5.9-12.5,12.5-12.5H162c10.3,0,19-7.9,19-18.5V63h-73.6    C92.3,63,80,76.1,80,91.6V452c0,15.5,12.3,28,27.4,28H256h148.6c15.1,0,27.4-12.5,27.4-28V91.6C432,76.1,419.7,63,404.6,63z" />
      </g>
      <rect height="16" width="112" x="144" y="192" />
      <rect height="16" width="160" x="144" y="288" />
      <rect height="16" width="129" x="144" y="384" />
      <rect height="16" width="176" x="144" y="336" />
      <rect height="16" width="208" x="144" y="240" />
    </g>
  </svg>
);
const Image = styled.img`
   {
    object-fit: contain;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    box-sizing: border-box;
    max-height: 500px;
  }
`;
const Wrapper = styled.div`
background:#4f4f4f;
min-height:100vh;
position:relative;
.submitted-key{
  background: #00574f;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  box-sizing: border-box;
  color: white;
}
.story-class{
  position:absolute;
  height: calc(100% - 22px);
  left:-300px;
  width:340px;
  background:#b69f53;
  padding:10px;
  box-sizing: border-box;
  transition:.3s all;
  z-index:100;
  overflow: auto;
  &.active{
    left:0px;
  }
  .head{
      img{
      margin-top:2px;
      float:right;
      cursor:pointer;
      &.close{
        transform:rotateY(180deg)
      }
    }
    
  }
  ul{
    
    padding:0;
    
    margin-top:20px;
    list-style:none;
    color:white;
    font-size:10px;
    li{
      position:relative;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items:center;
      gap: 10px;
      font-size: 1.2rem;
      margin:10px 0;
      &:hover{
        background:#78725e
      }
      img{
        object-fit:cover;
        &:hover{
          cursor:zoom-in
        }
      }
      span{
        cursor: pointer;
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .copied{
        top:5px;
        position:absolute;
        background:#077db3;
        color:white;
        padding:5px;
        left:60px;
        border-radius:10px;
        animation:1s rubberBand;
      }
      .remove{
        position: absolute;
        left: calc(100% - 20px);
        background: #d03d3d;
        color: white;
        padding: 0px;
        font-family: 'Circular';
        cursor:pointer;
        padding: 0 5px 4px;
        }
      }

    }
  }
  .btn-container{
    display:flex;
    gap:10px;
    .addAll{
      background:#077db3;
      border:none;
      padding:5px 10px;
      font-family: 'Circular';
      color:white;
      cursor:pointer;
      &:hover{
        filter: brightness(75%);
      }
  
    }
  }
  
  .zoomed{
    position:fixed;
    width: 90vw;
    height: 800px;
    background:#78725e;
     object-fit: contain;
    border:2px solid white;
  }
}
.name-url{
  height: 100px;
  display: flex;
  width:100%;
  align-items: center;
  justify-content: center;
  background: #077db3;
  font-size: 2rem;
  color: white;
  max-width:1200px;
  margin:0 auto;
  .copied{
    color:yellow;
    animation:1s rubberBand;
    
  }
 
  .clipboard-copy{
    cursor:pointer;
    margin: 0 10px;
    font-size: 1.3rem;
    transition:.1s linear;
    &:hover{
      background: #f9ff71;
      padding: 6px 6px 1px 6px;
      border-radius: 100%;
    }
    
  }
  input{
    font-size: 2rem;
    width: 65%;
    display: block;
    background: transparent;
    border: none;
    color: white;
    &:focus{
      outline:none
    }
  }
}
.grouplink{
  position: fixed;

  .move{
    text-align:right;
    cursor:move;
    color:white;
    right:10px;
    position:relative;
  }
  textarea{
    height:100px;
    width:540px; 
    background:transparent;
    color:white;
    position:relative;
    right:10px;
    padding:10px;
    resize: both;
  }
}
.s3-upload{
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    background: #686868;
    box-shadow: 1px 1px 10px #403e3e;
   
  .viewer{
      position:relative;
      width:50%;
      height:500px;
      .remove{
        position: absolute;
        right: 10px;
        top: 10px;
        background: #ff4b4b;
        color: white;
        padding: 9px 12px 11px 12px;
        cursor: pointer;
        box-shadow: -1px 3px 12px #00000078;
        &:hover{
          background:#6a1313
        }
      }
    }
    .uploadZone{
      width:50%;
      height: 500px;
      background: url(./upload.ico) no-repeat center;
      background-color: #272727;
      background-blend-mode: luminosity;
      position:relative;
      #upload-input{
        height:100%;
        width:100%;
        position:absolute;
        cursor:pointer;
        opacity:0
      }
    }
   
    @keyframes rubberBand {
      from {
          transform: scale3d(1, 1, 1);
      }
    
      30% {
          transform: scale3d(1.25, 0.75, 1);
      }
    
      40% {
          transform: scale3d(0.75, 1.25, 1);
      }
    
      50% {
          transform: scale3d(1.15, 0.85, 1);
      }
    
      65% {
          transform: scale3d(0.95, 1.05, 1);
      }
    
      75% {
          transform: scale3d(1.05, 0.95, 1);
      }
    
      to {
          transform: scale3d(1, 1, 1);
      }
    }
   
}

`;
export default App;
